<template>
    <div class="my-0 py-0" style="background: var(--v-component-base) !important">
        <v-row align="end" justify="end">
            <v-col cols="12" sm="12" md="8" lg="6">
                <el-input ref="searchBar" suffix-icon="el-icon-search" v-model="params.search" clearable
                    placeholder="Search">
                    <template slot="prepend">
                        <el-button @click="createDialog = !createDialog"><i class="el-icon-plus"></i>
                        </el-button>
                    </template>
                    <template slot="append">
                        <v-badge :content="computeFilterCount" :value="computeFilterCount > 0" color="primary"
                            offset-y="10">
                            <el-button @click="filterDialog = !filterDialog" el-tooltip="Advanced Filter">
                                <v-icon small>filter_alt</v-icon>
                            </el-button>
                        </v-badge>
                    </template>
                </el-input>
            </v-col>
        </v-row>
        <v-row class="mt-3">
            <v-col>
                <v-data-table height="65vh" dense fixed-header hide-default-footer :sort-by.sync="sortBy"
                    :sort-desc.sync="sortDesc" :loading="loading" :items="organisations.data" :headers="headers"
                    @click:row="routeOrganisation" :calculate-widths="true" style="cursor: pointer;" :options="{
                        page: page,
                        itemsPerPage: params.limit,
                        pageStart: page,
                        pageStop: Math.round(organisations.total / this.params.limit),
                        pageCount: Math.round(organisations.total / this.params.limit),
                        itemsLength: organisations.total
                    }">
                    <template v-slot:[`item.isActive`]="{ item }">
                        <v-chip class="ma-2" small outlined :color="item.isActive ? 'success' : 'danger'">
                            <v-icon small left>{{ item.isActive ? 'check' : 'close' }}</v-icon>
                            {{ item.isActive ? 'Active' : 'Inactive' }}
                        </v-chip>
                    </template>
                    <template v-slot:[`item.icon`]="{ item }">
                        <v-avatar>
                            <v-img v-if="item.icon" :src="item.icon" contain></v-img>
                        </v-avatar>
                    </template>
                    <template v-slot:[`item.countryRegion.name`]="{ item }">
                        <v-avatar size="32" class="mr-1" v-if="item.countryCode">
                            <v-img contain :src="`https://cdn.loglive.io/flags/4x3/${item.countryCode.toLowerCase()}.svg`">
                            </v-img>
                        </v-avatar>
                        <span v-if="item.countryRegion">
                            {{ item.countryRegion.name }}
                        </span>
                    </template>
                    <template v-slot:[`item.organisationType`]="{ item }">
                        <v-chip class="ma-2" small v-if="item.bank" outlined :color="item.bank ? 'success' : 'warning'">
                            Bank <v-icon small right>account_balance</v-icon>
                        </v-chip>
                        <v-chip class="ma-2" small v-if="item.buyer" outlined :color="item.buyer ? 'success' : 'warning'">
                            Buyer <v-icon small right>shopping_cart</v-icon>
                        </v-chip>
                        <v-chip class="ma-2" small v-if="item.coldstore" outlined
                            :color="item.coldstore ? 'success' : 'warning'">Coldstore <v-icon small right>store
                            </v-icon>
                        </v-chip>
                        <v-chip class="ma-2" small v-if="item.consignee" outlined
                            :color="item.consignee ? 'success' : 'warning'">Consignee <v-icon small right>
                                call_received
                            </v-icon>
                        </v-chip>
                        <v-chip class="ma-2" small v-if="item.forwarder" outlined
                            :color="item.forwarder ? 'success' : 'warning'">Forwarder <v-icon small right>
                                fast_forward
                            </v-icon>
                        </v-chip>
                        <v-chip class="ma-2" small v-if="item.notifyParty" outlined
                            :color="item.notifyParty ? 'success' : 'warning'">Notify Party <v-icon smallright>
                                notifications_active</v-icon>
                        </v-chip>
                        <v-chip class="ma-2" small v-if="item.producer" outlined
                            :color="item.producer ? 'success' : 'warning'">
                            Producer <v-icon small right>factory</v-icon>
                        </v-chip>
                        <v-chip class="ma-2" small v-if="item.shipper" outlined
                            :color="item.shipper ? 'success' : 'warning'">
                            Shipper <v-icon small right>local_shipping
                            </v-icon>
                        </v-chip>
                        <v-chip class="ma-2" small v-if="item.shippingLine" outlined
                            :color="item.shippingLine ? 'success' : 'warning'">Shipping Line<v-icon small right>
                                directions_boat
                            </v-icon>
                        </v-chip>
                        <v-chip class="ma-2" small v-if="item.vesselOperator" outlined
                            :color="item.vesselOperator ? 'success' : 'warning'">Vessel Operator<v-icon small right>
                                directions_boat
                            </v-icon>
                        </v-chip>
                        <v-chip class="ma-2" small v-if="item.governingBody" outlined
                            :color="item.governingBody ? 'success' : 'warning'">Governing Body<v-icon small right>
                                gavel
                            </v-icon>
                        </v-chip>
                        <v-chip class="ma-2" small v-if="item.pluginProvider" outlined
                            :color="item.pluginProvider ? 'success' : 'warning'">Plugin Provider<v-icon small right>
                                extension
                            </v-icon>
                        </v-chip>
                        <v-chip class="ma-2" small v-if="item.transporter" outlined
                            :color="item.transporter ? 'success' : 'warning'">Transporter<v-icon small right>
                                local_shipping
                            </v-icon>
                        </v-chip>
                        <v-tooltip top>
                            <template v-slot:activator="{ on }">
                                <v-chip v-on="on" class="ma-2" small v-if="item.vasProvider" outlined
                                    :color="item.vasProvider ? 'success' : 'warning'">VAS Provider<v-icon small right>
                                        settings_suggest
                                    </v-icon>
                                </v-chip>
                            </template>
                            <span>Value Added Service Provider</span>
                        </v-tooltip>
                    </template>
                </v-data-table>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="12" class="text-center">
                <el-pagination style="color: var(--v-primaryText-base)" :current-page.sync="page" :pager-count="5"
                    :page-size.sync="params.limit" :page-sizes="[10, 25, 50, 100]"
                    :layout="!$vuetify.breakpoint.mobile ? 'sizes, prev, pager, next, jumper, total' : 'prev, pager, next'"
                    :total="organisations.total">
                </el-pagination>
            </v-col>
        </v-row>

        <!-- Advanced Filter Dialog -->
        <v-dialog v-model="filterDialog" persistent max-width="600px">
            <v-card dark :loading="loading">
                <v-toolbar>
                    <v-card-title>
                        <span class="text-h5">Advanced filter</span>
                    </v-card-title>
                    <v-spacer></v-spacer>
                    <v-tooltip top>
                        <template v-slot:activator="{ on }">
                            <v-btn v-on="on" plain depressed elevation="0" :disabled="!filterActive" @click="clearFilters">
                                <v-icon>filter_alt_off</v-icon>
                            </v-btn>
                        </template>
                        <span>Clear Filters</span>
                    </v-tooltip>
                    <v-tooltip top>
                        <template v-slot:activator="{ on }">
                            <v-btn v-on="on" plain depressed elevation="0" @click="filterSearch">
                                <v-icon>filter_alt</v-icon>
                            </v-btn>
                        </template>
                        <span>Apply Filters</span>
                    </v-tooltip>
                    <v-tooltip top>
                        <template v-slot:activator="{ on }">
                            <v-btn v-on="on" plain depressed elevation="0" @click="closeFilterDialog()">
                                <v-icon>close</v-icon>
                            </v-btn>
                        </template>
                        <span>Close</span>
                    </v-tooltip>
                </v-toolbar>
                <v-card-text>
                    <v-container>
                        <v-autocomplete dense v-model="selectedFilterCountry" :items="countries" item-text="name"
                            item-value="name" return-object label="Country" outlined>
                            <template v-slot:selection="data">
                                <v-avatar size="32" class="mr-2" v-if="data.item">
                                    <v-img v-if="data.item.iso2" contain
                                        :src="`https://cdn.loglive.io/flags/4x3/${data.item.iso2.toLowerCase()}.svg`">
                                    </v-img>
                                </v-avatar>
                                <span v-if="data.item">
                                    {{ data.item.name }}
                                </span>
                            </template>
                            <template v-slot:item="data">
                                <v-list-item-avatar>
                                    <v-img contain v-if="data.item.iso2"
                                        :src="`https://cdn.loglive.io/flags/4x3/${data.item.iso2.toLowerCase()}.svg`">
                                    </v-img>
                                </v-list-item-avatar>
                                <v-list-item-content>
                                    <v-list-item-title v-html="data.item.name"></v-list-item-title>
                                </v-list-item-content>
                            </template>
                        </v-autocomplete>
                        <v-row justify="center">
                            <v-container fluid>
                                <v-chip class="ma-2" :outlined="searchItem.isActive ? false : true"
                                    @click="searchItem.isActive = !searchItem.isActive" color="success">
                                    <v-icon small left>done</v-icon> Active
                                </v-chip>
                                <v-chip class="ma-2" :outlined="searchItem.inActive ? false : true"
                                    @click="searchItem.inActive = !searchItem.inActive" color="danger">
                                    <v-icon small left>close</v-icon> Inactive
                                </v-chip>
                            </v-container>
                        </v-row>
                        <v-row justify="center">
                            <v-container fluid>
                                <v-chip class="ma-2" :outlined="searchItem.bank ? false : true"
                                    :color="searchItem.bank ? 'success' : 'grey'"
                                    @click="searchItem.bank = !searchItem.bank">
                                    Bank <v-icon small right>account_balance</v-icon>
                                </v-chip>
                                <v-chip class="ma-2" :outlined="searchItem.buyer ? false : true"
                                    :color="searchItem.buyer ? 'success' : 'grey'"
                                    @click="searchItem.buyer = !searchItem.buyer">
                                    Buyer
                                    <v-icon small right>shopping_cart</v-icon>
                                </v-chip>
                                <v-chip class="ma-2" :outlined="searchItem.coldstore ? false : true"
                                    :color="searchItem.coldstore ? 'success' : 'grey'"
                                    @click="searchItem.coldstore = !searchItem.coldstore">
                                    Coldstore <v-icon small right>store</v-icon>
                                </v-chip>
                                <v-chip class="ma-2" :outlined="searchItem.consignee ? false : true"
                                    :color="searchItem.consignee ? 'success' : 'grey'"
                                    @click="searchItem.consignee = !searchItem.consignee">
                                    Consignee <v-icon small right>call_received</v-icon>
                                </v-chip>
                                <v-chip class="ma-2" :outlined="searchItem.forwarder ? false : true"
                                    :color="searchItem.forwarder ? 'success' : 'grey'"
                                    @click="searchItem.forwarder = !searchItem.forwarder">
                                    Forwarder <v-icon small right>fast_forward</v-icon>
                                </v-chip>
                                <v-chip class="ma-2" :outlined="searchItem.notifyParty ? false : true"
                                    :color="searchItem.notifyParty ? 'success' : 'grey'"
                                    @click="searchItem.notifyParty = !searchItem.notifyParty">
                                    Notify Party <v-icon small right>notifications_active</v-icon>
                                </v-chip>
                                <v-chip class="ma-2" :outlined="searchItem.producer ? false : true"
                                    :color="searchItem.producer ? 'success' : 'grey'"
                                    @click="searchItem.producer = !searchItem.producer">
                                    Producer <v-icon small right>factory</v-icon>
                                </v-chip>
                                <v-chip class="ma-2" :outlined="searchItem.shipper ? false : true"
                                    :color="searchItem.shipper ? 'success' : 'grey'"
                                    @click="searchItem.shipper = !searchItem.shipper">
                                    Shipper <v-icon small right>north_east</v-icon>
                                </v-chip>
                                <v-chip class="ma-2" :outlined="searchItem.shippingLine ? false : true"
                                    :color="searchItem.shippingLine ? 'success' : 'grey'"
                                    @click="searchItem.shippingLine = !searchItem.shippingLine">
                                    Shipping Line <v-icon small right>directions_boat</v-icon>
                                </v-chip>
                                <v-chip class="ma-2" :outlined="searchItem.vesselOperator ? false : true"
                                    :color="searchItem.vesselOperator ? 'success' : 'grey'"
                                    @click="searchItem.vesselOperator = !searchItem.vesselOperator">
                                    Vessel Operator <v-icon small right>directions_boat</v-icon>
                                </v-chip>
                                <v-chip class="ma-2" :outlined="searchItem.governingBody ? false : true"
                                    :color="searchItem.governingBody ? 'success' : 'grey'"
                                    @click="searchItem.governingBody = !searchItem.governingBody">
                                    Governing Body <v-icon small right>gavel</v-icon>
                                </v-chip>
                                <v-chip class="ma-2" :outlined="searchItem.pluginProvider ? false : true"
                                    :color="searchItem.pluginProvider ? 'success' : 'grey'"
                                    @click="searchItem.pluginProvider = !searchItem.pluginProvider">
                                    Plugin Provider <v-icon small right>extension</v-icon>
                                </v-chip>
                                <v-chip class="ma-2" :outlined="searchItem.transporter ? false : true"
                                    :color="searchItem.transporter ? 'success' : 'grey'"
                                    @click="searchItem.transporter = !searchItem.transporter">
                                    Transporter <v-icon small right>local_shipping</v-icon>
                                </v-chip>
                                <v-tooltip top>
                                    <template v-slot:activator="{ on }">
                                        <v-chip v-on="on" class="ma-2" :outlined="searchItem.vasProvider ? false : true"
                                            :color="searchItem.vasProvider ? 'success' : 'grey'"
                                            @click="searchItem.vasProvider = !searchItem.vasProvider">
                                            VAS Provider <v-icon small right>settings_suggest</v-icon>
                                        </v-chip>
                                    </template>
                                    <span>Value Added Service Provider</span>
                                </v-tooltip>
                            </v-container>
                        </v-row>
                    </v-container>
                </v-card-text>
            </v-card>
        </v-dialog>

        <!-- Create Organisation Dialog -->
        <v-dialog v-model="createDialog" persistent max-width="600px">
            <v-card dark :loading="loading">
                <v-toolbar>
                    <v-card-title>
                        <span class="text-h5">New Organisation</span>
                    </v-card-title>
                    <v-spacer></v-spacer>
                    <v-btn plain depressed elevation="0" @click="createOrganisation()"
                        :disabled="(!newItem.name || !newItem.name.trim()) || !selectedCreateCountry || !selectedCreateCountry.iso2">
                        <v-icon>save</v-icon>
                    </v-btn>
                    <v-btn plain depressed elevation="0" @click="closeCreateDialog()">
                        <v-icon>close</v-icon>
                    </v-btn>
                </v-toolbar>
                <v-card-text>
                    <v-container>
                        <v-text-field v-model="newItem.name" outlined dense label="Organisation Name" required>
                        </v-text-field>
                        <v-text-field v-model="newItem.alias" outlined dense label="Alias">
                        </v-text-field>
                        <v-autocomplete dense v-model="selectedCreateCountry" :items="countries" item-text="name"
                            item-value="name" return-object label="Country" outlined>
                            <template v-slot:selection="data">
                                <v-avatar size="32" class="mr-2" v-if="data.item">
                                    <v-img v-if="data.item.iso2" contain
                                        :src="`https://cdn.loglive.io/flags/4x3/${data.item.iso2.toLowerCase()}.svg`">
                                    </v-img>
                                </v-avatar>
                                <span v-if="data.item">
                                    {{ data.item.name }}
                                </span>
                            </template>
                            <template v-slot:item="data">
                                <v-list-item-avatar>
                                    <v-img contain v-if="data.item.iso2"
                                        :src="`https://cdn.loglive.io/flags/4x3/${data.item.iso2.toLowerCase()}.svg`">
                                    </v-img>
                                </v-list-item-avatar>
                                <v-list-item-content>
                                    <v-list-item-title v-html="data.item.name"></v-list-item-title>
                                </v-list-item-content>
                            </template>
                        </v-autocomplete>
                        <v-card outlined class="mx-auto">
                            <v-card-subtitle>Network Functions</v-card-subtitle>
                            <v-card-text>
                                <v-chip class="ma-2" outlined @click="(newItem.bank = !newItem.bank)"
                                    :color="newItem.bank ? 'success' : 'grey'">Bank
                                    <v-icon small right>account_balance</v-icon>
                                </v-chip>
                                <v-chip class="ma-2" outlined @click="(newItem.buyer = !newItem.buyer)"
                                    :color="newItem.buyer ? 'success' : 'grey'">Buyer
                                    <v-icon small right>shopping_cart</v-icon>
                                </v-chip>
                                <v-chip class="ma-2" outlined @click="(newItem.coldstore = !newItem.coldstore)"
                                    :color="newItem.coldstore ? 'success' : 'grey'">
                                    Coldstore <v-icon small right>store</v-icon>
                                </v-chip>
                                <v-chip class="ma-2" outlined @click="(newItem.consignee = !newItem.consignee)"
                                    :color="newItem.consignee ? 'success' : 'grey'">
                                    Consignee <v-icon small right>call_received</v-icon>
                                </v-chip>
                                <v-chip class="ma-2" outlined @click="(newItem.forwarder = !newItem.forwarder)"
                                    :color="newItem.forwarder ? 'success' : 'grey'">
                                    Forwarder <v-icon small right>fast_forward</v-icon>
                                </v-chip>
                                <v-chip class="ma-2" outlined @click="(newItem.notifyParty = !newItem.notifyParty)"
                                    :color="newItem.notifyParty ? 'success' : 'grey'">
                                    Notify Party <v-icon smallright>notifications_active</v-icon>
                                </v-chip>
                                <v-chip class="ma-2" outlined @click="(newItem.producer = !newItem.producer)"
                                    :color="newItem.producer ? 'success' : 'grey'">
                                    Producer <v-icon small right>factory</v-icon>
                                </v-chip>
                                <v-chip class="ma-2" outlined @click="(newItem.shipper = !newItem.shipper)"
                                    :color="newItem.shipper ? 'success' : 'grey'">
                                    Shipper <v-icon small right>north_east</v-icon>
                                </v-chip>
                                <v-chip class="ma-2" outlined @click="(newItem.shippingLine = !newItem.shippingLine)"
                                    :color="newItem.shippingLine ? 'success' : 'grey'">
                                    Shipping Line <v-icon small right>directions_boat</v-icon>
                                </v-chip>
                                <v-chip class="ma-2" outlined @click="(newItem.vesselOperator = !newItem.vesselOperator)"
                                    :color="newItem.vesselOperator ? 'success' : 'grey'">
                                    Vessel Operator <v-icon small right>directions_boat</v-icon>
                                </v-chip>
                                <v-chip class="ma-2" outlined @click="(newItem.governingBody = !newItem.governingBody)"
                                    :color="newItem.governingBody ? 'success' : 'grey'">
                                    Governing Body <v-icon small right>gavel</v-icon>
                                </v-chip>
                                <v-chip class="ma-2" outlined @click="(newItem.pluginProvider = !newItem.pluginProvider)"
                                    :color="newItem.pluginProvider ? 'success' : 'grey'">
                                    Plugin Provider <v-icon small right>extension</v-icon>
                                </v-chip>
                                <v-chip class="ma-2" outlined @click="(newItem.transporter = !newItem.transporter)"
                                    :color="newItem.transporter ? 'success' : 'grey'">
                                    Transporter <v-icon small right>local_shipping</v-icon>
                                </v-chip>
                                <v-tooltip top>
                                    <template v-slot:activator="{ on }">
                                        <v-chip v-on="on" class="ma-2" :outlined="newItem.vasProvider ? false : true"
                                            :color="newItem.vasProvider ? 'success' : 'grey'"
                                            @click="newItem.vasProvider = !newItem.vasProvider">
                                            VAS Provider <v-icon small right>settings_suggest</v-icon>
                                        </v-chip>
                                    </template>
                                    <span>Value Added Service Provider</span>
                                </v-tooltip>
                            </v-card-text>
                        </v-card>
                    </v-container>
                </v-card-text>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
export default {
    data: () => ({
        createDialog: false,
        filterDialog: false,
        filterActive: false,
        dialogImageUrl: '',
        dialogVisible: false,
        headers: [
            {
                text: 'ID',
                value: 'id',
                align: 'center',
                sortable: false
            },
            {
                text: 'LogLive Code',
                value: 'logliveCode',
                align: 'center',
                sortable: false
            },
            {
                text: 'Status',
                value: 'isActive',
                align: 'center',
            },
            {
                text: 'Icon',
                value: 'icon',
                align: 'center',
            },
            {
                text: 'Name',
                value: 'name',
                align: 'center',
            },
            {
                text: 'Country',
                value: 'countryRegion.name',
                sortable: false,
                align: 'center',
                width: '250px'
            },
            // Changed busPro org countryCode to iso2 from iso3
            {
                text: 'Country Code',
                value: 'countryRegion.iso3',
                sortable: false,
                align: 'center'
            },
            {
                text: 'Organisation Type',
                value: 'organisationType',
                sortable: false,
                align: 'center'
            },
            {
                text: 'Contact Email',
                value: 'emailAddress',
                align: 'center'
            },
            {
                text: 'Contact Phone',
                value: 'telephoneNumber',
                align: 'center'
            },
            {
                text: 'CBR ID',
                value: 'cbrId',
                align: 'center'
            },
            // {
            //     text: 'Company Type',
            //     value: 'companyType',
            //     align: 'center'
            // },
        ],
        loading: false,
        page: 1,
        params: {
            limit: 12,
            offset: 0,
            search: null,
            filter: { isActive: [true] }
        },
        passwordType: 'password',
        item: {},
        newItem: {
            isDeleted: false,
            isActive: true,
            bank: false,
            buyer: false,
            coldstore: false,
            consignee: false,
            forwarder: false,
            notifyParty: false,
            producer: false,
            shipper: false,
            shippingLine: false,
            vesselOperator:false,
            pluginProvider: false,
            governingBody: false,
            transporter: false,
            vasProvider: false,
        },
        tags: [],
        tagKey: 0,
        organisations: {
            total: 0,
            data: []
        },
        countries: [],
        selectedCreateCountry: { name: '', iso: '' },
        selectedFilterCountry: { name: '', iso: '' },
        sortBy: 'name',
        sortDesc: false,
        tagSearch: '',
        userOrganisation: false,
        searchTimer: undefined,
        searchItem: {
            isActive: true,
            inActive: false,
            bank: false,
            buyer: false,
            coldstore: false,
            consignee: false,
            forwarder: false,
            notifyParty: false,
            producer: false,
            shipper: false,
            shippingLine: false,
            vesselOperator:false,
            pluginProvider: false,
            governingBody: false,
            transporter: false,
            vasProvider: false,
            country: null
        },
    }),
    computed: {
        computeFilterCount() {
            let count = 0
            for (var key in this.searchItem) {
                if (key == 'isActive' && !this.searchItem.isActive) { count++ }
                if (key == 'inActive' && this.searchItem.inActive) { count++ }
                if (key == 'bank' && this.searchItem.bank) { count++ }
                if (key == 'buyer' && this.searchItem.buyer) { count++ }
                if (key == 'coldstore' && this.searchItem.coldstore) { count++ }
                if (key == 'consignee' && this.searchItem.consignee) { count++ }
                if (key == 'forwarder' && this.searchItem.forwarder) { count++ }
                if (key == 'notifyParty' && this.searchItem.notifyParty) { count++ }
                if (key == 'producer' && this.searchItem.producer) { count++ }
                if (key == 'shipper' && this.searchItem.shipper) { count++ }
                if (key == 'shippingLine' && this.searchItem.shippingLine) { count++ }
                if (key == 'vesselOperator' && this.searchItem.vesselOperator) { count++ }
                if (key == 'pluginProvider' && this.searchItem.pluginProvider) { count++ }
                if (key == 'governingBody' && this.searchItem.governingBody) { count++ }
                if (key == 'transporter' && this.searchItem.transporter) { count++ }
                if (key == 'vasProvider' && this.searchItem.vasProvider) { count++ }
                if (key == 'country' && this.searchItem.country) { count++ }
            }
            return count
        }
    },
    watch: {
        async page(value) {
            this.params.offset = (value - 1) * this.params.limit
            sessionStorage.setItem('orgAdminParams', JSON.stringify(this.params))
            sessionStorage.setItem('orgAdminPage', JSON.stringify(value))
            await this.getOrganisations()
        },
        'params.limit': {
            immediate: true,
            async handler() {
                this.page = 1;
                sessionStorage.setItem('orgAdminParams', JSON.stringify(this.params))
                await this.getOrganisations()
            }
        },
        'params.search': {
            async handler() {
                this.page = 1;
                sessionStorage.setItem('orgAdminParams', JSON.stringify(this.params))
                await this.getOrganisations()
            }
        },
        'organisations.total': {
            handler() {
                if (sessionStorage.orgAdminPage) { this.page = JSON.parse(sessionStorage.orgAdminPage) }
            }
        },
        selectedFilterCountry(value) {
            if (value && value.name) {
                this.searchItem.country = value.name
            }
        },
        sortBy(value) {
            this.params.sortBy = value
            sessionStorage.setItem('orgAdminParams', JSON.stringify(this.params))
            this.getOrganisations()
        },
        sortDesc(value) {
            this.params.sortDesc = value
            sessionStorage.setItem('orgAdminParams', JSON.stringify(this.params))
            this.getOrganisations()
        },
    },
    async created() {
        this.getTags()
        await this.getCountries()
    },
    mounted() {
        this.setSessionParams();
        this.getOrganisations();
    },
    // beforeRouterEnter(to, from, next) {

    //     console.log(to)
    //     console.log(from)
    //     console.log(next)
    // },
    methods: {
        async createOrganisation() {
            this.loading = true;
            this.createDialog = false;
            this.newItem.country = this.selectedCreateCountry.name
            this.newItem.countryCode = this.selectedCreateCountry.iso2

            // let inputCreateLLCode = {
            // length: 6,
            // countryCode: this.newItem.countryCode
            // }
            // this.newItem.logliveCode = await this.$API.createLLCode(inputCreateLLCode);


            await this.$API.createOrganisationAdmin(this.newItem);

            this.$message.success("Organisation successfully created!");

            this.resetOrgObject()
            await this.getOrganisations();
        },
        async closeCreateDialog() {
            this.resetOrgObject()
            this.createDialog = false;
        },
        async getOrganisations() {
            if (this.searchTimer) { clearTimeout(this.searchTimer) }
            this.loading = true
            this.searchTimer = setTimeout(async () => {
                this.organisations = await this.$API.listOrganisationsAdmin({
                    params: this.params
                });
                this.loading = false
            }, 500)
        },
        async getCountries() {
            let result = await this.$API.searchCountries();
            for (let i = 0; i < result.data.length; i++) {
                this.countries.push({
                    name: result.data[i].name,
                    iso2: result.data[i].iso2
                })
            }
        },
        async filterSearch() {
            this.filterActive = true
            this.params.filter = {}

            if (this.searchItem.isActive && this.searchItem.inActive) { this.params.filter.isActive = [true, false] }
            else if (this.searchItem.isActive && !this.searchItem.inActive) { this.params.filter.isActive = [true] }
            else if (!this.searchItem.isActive && this.searchItem.inActive) { this.params.filter.isActive = [false] }

            this.params.filter.country = [this.searchItem.country]
            this.params.filter.bank = [this.searchItem.bank];
            this.params.filter.consignee = [this.searchItem.consignee];
            this.params.filter.buyer = [this.searchItem.buyer];
            this.params.filter.shipper = [this.searchItem.shipper];
            this.params.filter.producer = [this.searchItem.producer];
            this.params.filter.notifyParty = [this.searchItem.notifyParty];
            this.params.filter.coldstore = [this.searchItem.coldstore];
            this.params.filter.forwarder = [this.searchItem.forwarder];
            this.params.filter.shippingLine = [this.searchItem.shippingLine];
            this.params.filter.vesselOperator = [this.searchItem.vesselOperator];
            this.params.filter.governingBody = [this.searchItem.governingBody];
            this.params.filter.pluginProvider = [this.searchItem.pluginProvider];
            this.params.filter.transporter = [this.searchItem.transporter];
            this.params.filter.vasProvider = [this.searchItem.vasProvider];

            this.params.filter = JSON.parse(JSON.stringify(this.params.filter))
            sessionStorage.setItem('orgAdminFilterParams', JSON.stringify(this.params.filter))
            sessionStorage.setItem('orgAdminFilter', JSON.stringify(this.filterActive))
            sessionStorage.setItem('orgAdminFilterCountry', JSON.stringify(this.selectedFilterCountry))
            this.filterDialog = false
            this.getOrganisations()
        },
        clearFilters() {
            this.filterActive = false
            this.clearFilterDialog();
            this.params.filter = { isActive: [true] }
            this.getOrganisations()
        },
        async clearFilterDialog() {
            this.filterDialog = false
            this.selectedFilterCountry = undefined
            this.page = 1
            this.searchItem = {
                country: null,
                isActive: true,
                inActive: false,
                bank: false,
                buyer: false,
                coldstore: false,
                consignee: false,
                forwarder: false,
                notifyParty: false,
                producer: false,
                shipper: false,
                shippingLine: false,
                vesselOperator: false,
                pluginProvider: false,
                governingBody: false,
                transporter: false,
                vasProvider: false
            };
            sessionStorage.removeItem('orgAdminFilterParams')
            sessionStorage.removeItem('orgAdminFilter')
            sessionStorage.removeItem('orgAdminFilterCountry')
        },
        async getTags() {
            this.tags = await this.$API.getTagsByType("organisation")
        },
        async handleSelect(item) {
            if (this.item.id) {
                let result = await this.$API.createOrganisationTag({
                    tagId: item.id,
                    organisationId: this.item.id,
                });
                this.item.organisationTags.push(result);
            } else {
                this.item.organisationTags.push({
                    tag: item,
                    tagId: item.id,
                    organisationId: this.item.id,
                });
            }
        },
        resetOrgObject() {
            this.selectedCreateCountry = { name: '', iso: '' }
            this.newItem = {
                isDeleted: false,
                isActive: true,
                bank: false,
                buyer: false,
                coldstore: false,
                consignee: false,
                forwarder: false,
                notifyParty: false,
                producer: false,
                shipper: false,
                shippingLine: false,
                vesselOperator: false,
                pluginProvider: false,
                governingBody: false,
                transporter: false,
                vasProvider: false,
            }
        },
        handleRemove(file, fileList) {
            console.log(file, fileList);
        },
        handlePictureCardPreview(file) {
            this.dialogImageUrl = file.url;
            this.dialogVisible = true;
        },
        querySearch(queryString, cb) {
            var links = this.tags;
            var results = queryString
                ? links.filter(this.createFilter(queryString))
                : links;
            // call callback function to return suggestions
            cb(results);
        },
        createFilter(queryString) {
            return (link) => {
                return link.name.toLowerCase().indexOf(queryString.toLowerCase()) === 0;
            };
        },
        async removeTag(tag) {
            if (tag.id) {
                await this.$API.updateOrganisationTag({
                    id: tag.id,
                    isActive: false,
                });
            }
            let index = this.item.organisationTags.indexOf(tag);
            this.item.organisationTags.splice(index, 1);
        },
        removeOrganisation(org) {
            this.$confirm(
                "Are you sure you want to delete this Organisation?",
                "Confirm",
                {
                    center: true,
                    confirmButtonText: "Yes",
                    cancelButtonText: "No",
                    type: "plain",
                }
            )
                .then(async () => {
                    await this.$API.updateOrganisationAdmin({
                        id: org.id,
                        isActive: false,
                        isDeleted: true,
                    });
                    this.getOrganisations();
                    this.$message({
                        type: "success",
                        message: "Successfully deleted!",
                    });
                })
                .catch(() => {
                    this.$message({
                        type: "info",
                        message: "Delete canceled",
                    });
                });
        },
        async saveOrganisation() {
            this.loading = true;
            if (this.item.id) {
                await this.$API.updateOrganisationAdmin(this.item);
                this.$message.success("Update successful!");
            } else {
                await this.$API.createOrganisationAdmin(this.item);
                this.$message.success("Successfully create user!");
                this.getOrganisations();
            }
            this.loading = false;
            this.item = {};
            this.userOrganisation = false;
        },
        routeOrganisation(click, row) {
            this.$router.push({
                path: "/organisation/" + row.item.id,
            });
        },
        clear() {
            this.loading = false;
            this.item = {};
            this.userOrganisation = false;
        },
        focusOnSearch() {
            this.$nextTick(() => { });
        },
        closeFilterDialog() {
            if (!this.filterActive) { this.clearFilterDialog() }
            this.filterDialog = false
        },
        setSessionParams() {
            if (sessionStorage.orgAdminParams) { this.params = JSON.parse(sessionStorage.orgAdminParams) }
            if (sessionStorage.orgAdminFilterParams) { this.params.filter = JSON.parse(sessionStorage.orgAdminFilterParams) }
            if (sessionStorage.orgAdminFilter) {


                if (sessionStorage.orgAdminFilterCountry) {
                    this.selectedFilterCountry = JSON.parse(sessionStorage.orgAdminFilterCountry)
                    this.searchItem.country = this.params.filter.country[0]
                }

                if (this.params.filter.isActive.length > 1) {
                    this.searchItem.isActive = true
                    this.searchItem.inActive = true
                } else if (this.params.filter.isActive.length == 1) {
                    if (this.params.filter.isActive[0] == true) {
                        this.searchItem.isActive = true
                        this.searchItem.inActive = false
                    }
                    if (this.params.filter.isActive[0] == false) {
                        this.searchItem.isActive = false
                        this.searchItem.inActive = true
                    }
                }

                this.searchItem.bank = this.params.filter.bank[0]
                this.searchItem.consignee = this.params.filter.consignee[0]
                this.searchItem.buyer = this.params.filter.buyer[0]
                this.searchItem.shipper = this.params.filter.shipper[0]
                this.searchItem.producer = this.params.filter.producer[0]
                this.searchItem.notifyParty = this.params.filter.notifyParty[0]
                this.searchItem.coldstore = this.params.filter.coldstore[0]
                this.searchItem.forwarder = this.params.filter.forwarder[0]
                this.searchItem.shippingLine = this.params.filter.shippingLine[0]
                this.searchItem.vesselOperator = this.params.filter.vesselOperator[0]
                this.searchItem.governingBody = this.params.filter.governingBody[0]
                this.searchItem.pluginProvider = this.params.filter.pluginProvider[0]
                this.searchItem.transporter = this.params.filter.transporter[0]
                this.searchItem.vasProvider = this.params.filter.vasProvider[0]

            }
        }
    },
};
</script>

<style scoped>
.modal-form {
    width: 100%;
    align-items: center;
    justify-content: center;
    margin: 0;
    padding: 10px;
}
</style>
